import moment from 'moment';

import * as reputationHelper from './reputationHelper';

const initialState = {
  reputation: {},
  engage: {},
};


const locationReducer = (state = initialState, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('locationReducer action: ', action);
  }
  switch (action.type) {

    /* Get Reputation Sentiments Data */

    case "GET_SENTIMENTS_DATA": {
      return reputationHelper.getSentimentsData(state, action);
    }
    case "GET_SENTIMENTS_DATA_SUCCESS": {
      return reputationHelper.getSentimentsDataSuccess(state, action);
    }
    case "GET_SENTIMENTS_DATA_ERROR": {
      return reputationHelper.getSentimentsDataError(state, action);
    }


    /* Get Reputation Reviews Data */

    case "GET_REVIEWS_DATA": {
      return reputationHelper.getReviewsData(state, action);
    }
    case "GET_REVIEWS_DATA_SUCCESS": {
      return reputationHelper.getReviewsDataSuccess(state, action);
    }
    case "GET_REVIEWS_DATA_ERROR": {
      return reputationHelper.getReviewsDataError(state, action);
    }

    case 'USER_LOGOUT': {
      return initialState;
    }

  }
  return state;
};
export default locationReducer;
