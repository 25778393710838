import React, {Component, useEffect, useRef, useState} from 'react';
import {withFauxDOM} from 'react-faux-dom'

import * as d3 from "d3";
import './style.css';

const MIN_BAR_WIDTH = 24;

function ChartRowHeader(props) {
  let barMaxWidth = (props.width - 180 - 40*2)/2;
  let colLeftMaxWidth = (props.width - 180)/2;
  const onNegativeOrderClick = (e) => { props.onNegativeOrderClick() };
  const onPositiveOrderClick = (e) => { props.onPositiveOrderClick() };
  const onMentionOrderClick = (e) => { props.onMentionOrderClick() };
  return (
    <div className="chartRow header">
      <div className="colLeft" style={{'width': colLeftMaxWidth}}>
        <div className="barBox">
          <span onClick={onNegativeOrderClick}>
            Sort by negative mentions
            {
              props.negativeOrderAscending
              ? <i className="fas fa-sort-amount-down"></i>
              : <i className="fas fa-sort-amount-up"></i>
            }
          </span>
        </div>
      </div>
      <div className="colMiddle">
        <div className="labelBox">
          <span onClick={onMentionOrderClick}>
            {
              props.mentionOrderAscending
              ? <i className="fas fa-sort-amount-down"></i>
              : <i className="fas fa-sort-amount-up"></i>
            }
          </span>
        </div>
      </div>
      {/*<div className="colTwo">
      </div>*/}
      <div className="colRight" style={{'width': colLeftMaxWidth}}>
        <div className="barBox">
          <span onClick={onPositiveOrderClick}>
            {
              props.positiveOrderAscending
              ? <i className="fas fa-sort-amount-down"></i>
              : <i className="fas fa-sort-amount-up"></i>
            }
            Sort by positive mentions
          </span>
        </div>
      </div>
    </div>
  )
}

function ChartRowAxis(props) {
  let barMaxWidth = (props.width - 180 - 40*2)/2;
  let colLeftMaxWidth = (props.width - 180)/2;
  return (
    <div className="chartRow chartRowAxis">
      <div className="colOne">
        <div className="labelBox">
        </div>
      </div>
      {/*<div className="colTwo">
      </div>*/}
      <div className="colThree">
        <div className="barBox">
          Out of 100
          {/*
            axisValues.map((item) => {
              return (
                <div className="axisItem" style={axisItemStyle}>{item}</div>
              )
            })
          */}
        </div>
      </div>
    </div>
  )
}

function ChartRow(props) {
  const onMentionDetailsClick = () => {
    props.onMentionDetailsClick({ data: props.data });
  };
  const onNegativeMentionDetailsClick = () => {
    const tempData = { name: props.data.name };
    tempData.details = {
      negative: props.data.details ? props.data.details.negative : null,
    }
    // props.onMentionDetailsClick(tempData);
    props.onMentionDetailsClick({ data: props.data, default: 'negative'});
  };
  const onPositiveMentionDetailsClick = () => {
    props.onMentionDetailsClick({ data: props.data, default: 'positive'});
  };

  let barMaxWidth = (props.width - 180 - 40*2)/2;
  let colLeftMaxWidth = (props.width - 180)/2;
  if (process.env.NODE_ENV !== 'production') {
    console.log('SentimentMentionChart barMaxWidth: ', barMaxWidth, ' # maxValue: ', props.maxValue);
    console.log('SentimentMentionChart maxPositiveValue: ', props.maxPositiveValue, ' # maxNegativeValue: ', props.maxNegativeValue);
  }
  let positiveBarShapeStyle = {};
  positiveBarShapeStyle['width'] = (barMaxWidth/props.maxValue)*props.data.positive;
  let negativeBarShapeStyle = {};
  negativeBarShapeStyle['width'] = (barMaxWidth/props.maxValue)*props.data.negative;
  return (
    <div className="chartRow">
      <div className="colLeft" style={{'width': colLeftMaxWidth}}>
        <div className="barBox">
          {
            props.data.negative
            ?
              <div className="barLabel">
                {props.data.negative}
              </div>
            : null
          }
          <div className="barShape" style={negativeBarShapeStyle} onClick={onNegativeMentionDetailsClick}>
          </div>
        </div>
      </div>
      <div className="colMiddle">
        <div className="labelBox">
          <p className="name">{props.data.name}</p>
          <p className="mention" onClick={onMentionDetailsClick}>{props.data.total_mentions} mentions</p>
        </div>
      </div>
      {/*<div className="colTwo">
      </div>*/}
      <div className="colRight" style={{'width': colLeftMaxWidth}}>
        <div className="barBox">
          <div className="barShape" style={positiveBarShapeStyle} onClick={onPositiveMentionDetailsClick}>
          </div>
          {
            props.data.positive
            ?
              <div className="barLabel">
                {props.data.positive}
              </div>
            : null
          }
        </div>
      </div>
    </div>
  )
}

function SentimentMentionChart(props) {
  const [margin, setMargin] = useState({ top: 60, right: 60, bottom: 60, left: 160 });
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(null);
  const [maxPositiveValue, setMaxPositiveValue] = useState(null);
  const [maxNegativeValue, setMaxNegativeValue] = useState(null);

  const [mentionOrderAscending, setMentionOrderAscending] = useState(false);
  const [positiveOrderAscending, setPositiveOrderAscending] = useState(false);
  const [negativeOrderAscending, setNegativeOrderAscending] = useState(false);

  // useEffect(() => {
  //   let tempData = [{
  //               "name": "Room Amenities",
  //               "total_mentions": 76,
  //               "positive": 34,
  //               "negative": 12,
  //               "label": "12 mentions",
  //               "value": 20,
  //   },
  //       {
  //           "name": "Bananas",
  //           "total_mentions": 26,
  //           "positive": 14,
  //           "negative": 32,
  //           "label": "22 mentions",
  //           "value": 12,
  //   },
  //       {
  //           "name": "Grapes",
  //           "total_mentions": 34,
  //           "positive": 22,
  //           "negative": 2,
  //           "label": "16 mentions",
  //           "value": 19,
  //   },
  //       {
  //           "name": "Customer Support",
  //           "total_mentions": 55,
  //           "positive": 33,
  //           "negative": 22,
  //           "label": "12 mentions",
  //           "value": 5,
  //   },
  //       {
  //           "name": "Limes",
  //           "total_mentions": 56,
  //           "positive": 75,
  //           "negative": 1,
  //           "label": "42 mentions",
  //           "value": 16,
  //   },
  //       {
  //           "name": "Oranges",
  //           "total_mentions": 6,
  //           "positive": 4,
  //           "negative": 2,
  //           "label": "31 mentions",
  //           "value": 26,
  //   },
  //       {
  //           "name": "Pears",
  //           "total_mentions": 36,
  //           "positive": 4,
  //           "negative": 10,
  //           "label": "82 mentions",
  //           "value": 30,
  //   }];
  //   tempData = tempData.sort(function (a, b) {
  //     return d3.ascending(a.total_mentions, b.total_mentions);
  //   });
  //   setData(tempData);
  //   // setMaxValue(tempData[tempData.length-1].total_mentions);
  //   tempData.sort((a,b) => b.positive - a.positive);
  //   console.log('SentimentMentionChart tempData before setMaxPositiveValue: ', tempData);
  //   console.log('SentimentMentionChart tempData setMaxPositiveValue: ', tempData);
  //   let tempMaxPostiveValue = tempData[0].positive;
  //   setMaxPositiveValue(tempData[0].positive);
  //
  //   tempData.sort((a,b) => b.negative - a.negative);
  //   console.log('SentimentMentionChart tempData setMaxNegativeValue: ', tempData);
  //   let tempMaxNegativeValue = tempData[0].negative;
  //   setMaxNegativeValue(tempData[0].negative);
  //
  //   if (tempMaxPostiveValue > tempMaxNegativeValue) {
  //     setMaxValue(tempMaxPostiveValue);
  //   } else {
  //     setMaxValue(tempMaxNegativeValue);
  //   }
  //
  //   tempData.sort((a,b) => b.total_mentions - a.total_mentions);
  //   setData(tempData);
  //
  // }, []);

  useEffect(() => {
    if (props.data) {
      const { overall, weekly, monthly, review_data } = props.data;
      let tempData = [];
      if (!overall) return;
      Object.keys(overall || {}).forEach((item) => {
        if ('overall' in overall[item] && overall[item].overall) {
          tempData.push({
            name: item,
            score: overall[item].overall,
            total_mentions: (overall[item].positive ? overall[item].positive.length : 0) +
              (overall[item].negative ? overall[item].negative.length : 0),
            positive: overall[item].positive ? overall[item].positive.length : 0,
            negative: overall[item].negative ? overall[item].negative.length : 0,
            details: overall[item],
          });
        }
      });

      if (tempData.length) {
        tempData.sort((a,b) => b.positive - a.positive);
        if (process.env.NODE_ENV !== 'production') {
          console.log('SentimentMentionChart tempData before setMaxPositiveValue: ', tempData);
          console.log('SentimentMentionChart tempData setMaxPositiveValue: ', tempData);
        }
        let tempMaxPostiveValue = tempData[0].positive;
        setMaxPositiveValue(tempData[0].positive);

        tempData.sort((a,b) => b.negative - a.negative);
        if (process.env.NODE_ENV !== 'production') {
          console.log('SentimentMentionChart tempData setMaxNegativeValue: ', tempData);
        }
        let tempMaxNegativeValue = tempData[0].negative;
        setMaxNegativeValue(tempData[0].negative);

        if (tempMaxPostiveValue > tempMaxNegativeValue) {
          setMaxValue(tempMaxPostiveValue);
        } else {
          setMaxValue(tempMaxNegativeValue);
        }

        tempData.sort((a,b) => b.total_mentions - a.total_mentions);
        setData(tempData);
      }
    }
  }, [props.data]);

  const onMentionOrderClick = (e) => {
    let tempData = [ ...data ];
    if (mentionOrderAscending) {
      tempData.sort((a,b) => b.total_mentions - a.total_mentions);
    } else {
      tempData.sort((a,b) => a.total_mentions - b.total_mentions);
    }
    setData(tempData);
    setMentionOrderAscending(mentionOrderAscending => !mentionOrderAscending);
    // gtag('event', 'gt_click', {
    //   'event_category': `Charts - Sentiments Mentions - Mentions order`,
    //   'event_label': `Mentions order`,
    //   'value': `${mentionOrderAscending}`,
    //   user_id: props.userprops.user.uid,
    // });
  };
  const onPositiveOrderClick = (e) => {
    let tempData = [ ...data ];
    if (positiveOrderAscending) {
      tempData.sort((a,b) => b.positive - a.positive);
    } else {
      tempData.sort((a,b) => a.positive - b.positive);
    }
    setData(tempData);
    setPositiveOrderAscending(positiveOrderAscending => !positiveOrderAscending);
    // gtag('event', 'gt_click', {
    //   'event_category': `Charts - Sentiments Mentions - Positive mentions order`,
    //   'event_label': `Positive mentions order`,
    //   'value': `${positiveOrderAscending}`,
    //   user_id: props.userprops.user.uid,
    // });
  };
  const onNegativeOrderClick = (e) => {
    let tempData = [ ...data ];
    if (negativeOrderAscending) {
      tempData.sort((a,b) => b.negative - a.negative);
    } else {
      tempData.sort((a,b) => a.negative - b.negative);
    }
    setData(tempData);
    setNegativeOrderAscending(negativeOrderAscending => !negativeOrderAscending);
    // gtag('event', 'gt_click', {
    //   'event_category': `Charts - Sentiments Mentions - Negative mentions order`,
    //   'event_label': `Negative mentions order`,
    //   'value': `${negativeOrderAscending}`,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const cStyle = {};
  cStyle['width'] = props.width;

  if (process.env.NODE_ENV !== 'production') {
    console.log('SentimentMentionChart data: ', data);
  }
  return (
    <div className="mentionChartWrap genericChartWrap" style={cStyle}>
      <div className="headerRow">
        <p className="title">Total Mentions</p>
        <p className="subTitle">What your guests are talking about..</p>
      </div>
      {
        data.length > 0 &&
        <ChartRowHeader width={props.width}
          negativeOrderAscending={negativeOrderAscending}
          positiveOrderAscending={positiveOrderAscending}
          mentionOrderAscending={mentionOrderAscending}
          onMentionOrderClick={onMentionOrderClick}
          onNegativeOrderClick={onNegativeOrderClick}
          onPositiveOrderClick={onPositiveOrderClick}
        />
      }
      {
        data.length
        ?
          data.map((item) => {
            return <ChartRow key={`smc_${item.name}`} data={item} width={props.width} maxValue={maxValue}
              maxPositiveValue={maxPositiveValue} maxNegativeValue={maxNegativeValue}
              onMentionDetailsClick={props.onMentionDetailsClick}
            />
          })
        : <p>No Data</p>
      }
      {/*<ChartRowAxis width={props.width} maxValue={maxValue} maxPositiveValue={maxPositiveValue} maxNegativeValue={maxNegativeValue} />*/}
    </div>
  )
}

export default withFauxDOM(SentimentMentionChart);
