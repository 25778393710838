import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { getDataAction } from '../../redux/actions/getDataActions';

import SentimentScoreChart from '../Charts/SentimentScoreChart';


function About(props) {
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    getSentimentsData();
  }, []);

  // const [name, setName] = useState(user.name);
  // const [age, setAge] = useState(user.name);

  const getSentimentsData = (startDate, endDate) => {
    dispatch(getDataAction({
      op: 'fs_get_sentiments_data',
      params: {
        location__id: 'wZYeknJ4QgyGXj7Jm', // urlParams.locationidentifier,
        // start_date: moment(startDate).format('YYYY-MM-DD'),
        // end_date: moment(endDate).format('YYYY-MM-DD'),
      },
    }));
  };

  const onMentionDetailsClick = (e) => {

  };

  return (
    <div className="App">
      <h1>Graphs</h1>
      <Link to="/about">About</Link>
      <p onClick={(e) => {navigate('/about/aaaa')}}>useNavigate Home Params</p>
      <SentimentScoreChart
        // width={(windowState.window.windowWidth - 196 - 1 - 40 - 60 - 285 + 16 )}
        width={400}
        height={260}
        data={locationState.reputation.sentiments}
        onMentionDetailsClick={onMentionDetailsClick}
      />
    </div>
  );
}

export default About;
