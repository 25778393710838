import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import About from '../About';
import Home from '../Home';
import Sentiments from '../Sentiments';
import Reviews from '../Reviews';

import setWindowAttributes from '../../redux/actions/windowActions';

import { sendEmailAlert } from '../../helpers';

import './Wrapper.css';

const gtconfig = require('../../gtconfig');

function Wrapper() {
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    function handleResize() {
      dispatch(setWindowAttributes({
        windowWidth: document.body.clientWidth,
        windowHeight: window.innerHeight,
      }));
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={'wrp'}>
      <Routes>
        <Route path="bfajkzwj15-sentiments/:appid/:locationidentifier/:dateparams" element={<Sentiments />} />
        <Route path="coygel9kgw-reviews/:appid/:locationidentifier/:dateparams" element={<Reviews />} />
      </Routes>
      <Outlet />
    </div>
  );
}

export default Wrapper;
