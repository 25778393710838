import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';
import queryString from 'query-string';

import { getDataAction } from '../../redux/actions/getDataActions';

import ReviewRatingChartFaux from '../Charts/ReviewRatingChartFaux';

import './style.css';

const gtconfig = require('../../gtconfig');

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function Reviews(props) {
  const windowState = useSelector((state) => state.windowReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [showMentionDetailsModal, setShowMentionDetailsModal] = useState(false);
  const mentionDetailsModalRef = useRef();
  // useOnClickOutside(mentionDetailsModalRef, useCallback(() => onMentionDetailsModalCloseClick()));
  const [mentionDetailsData, setMentionDetailsData] = useState(null);
  const [mentionDetailsDefault, setMentionDetailsDefault] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  // const [name, setName] = useState(user.name);
  // const [age, setAge] = useState(user.name);

  const getData = () => {
    console.log('urlParams: ', urlParams);
    console.log('location: ', location);
    const parsed = queryString.parse(location.search);
    console.log('parsed: ', parsed);
    if (urlParams.locationidentifier && urlParams.appid && urlParams.dateparams) {
      let startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
      let endDate = moment().format('YYYY-MM-DD');
      if (urlParams.dateparams.indexOf('_') > -1) {
        let tArr = urlParams.dateparams.split('_');
        startDate = tArr[0];
        endDate = tArr[1];
      }
      const params = {
        location__id: urlParams.locationidentifier, // 'wZYeknJ4QgyGXj7Jm', // urlParams.locationidentifier,
        start_date: startDate,
        end_date: endDate,
      };
      const { ota, rating } = parsed;
      if (ota || rating) {
        params.ota_filter = {};
        params.rating_filter = {};
        if (ota) {
          let tArr = ota.split(',');
          tArr.forEach((item) => {
            params.ota_filter[item] = true;
          })
        } else { params.ota_filter['all'] = true; }
        if (rating) {
          let tArr = rating.split(',');
          tArr.forEach((item) => {
            params.rating_filter[item] = true;
          })
        } else { params.rating_filter['all'] = true; }
      }
    
      dispatch(getDataAction({
        op: 'fs_get_reviews_data_chart',
        params,
      }));
    }
  };

  if (!locationState.reputation || !locationState.reputation.reviews) return null;

  const reviews = locationState.reputation.reviews || {};

  if (reviews.getInProgress) return (
    <div className="gPnlSplLdng">
      <div className="innerWrap">
        <i className="fa fa-spinner fa-spin" />
        <img src={gtconfig.LOADER_URL} />
      </div>
    </div>
  )


  if (!reviews.trend_data) return null;



  return (
    <>
      <div className="mb">
        <ReviewRatingChartFaux
          windowState={windowState}
          width={(windowState.window.windowWidth)}
          height={300}
          data={reviews.trend_data}
          // hideRating={!ratingFilterValues['all']}
          // showOnlyDaily={dateRangeLastMonthValue['value'] === 'last-7-days' || dateRangeLastMonthValue['value'] === 'last-15-days'}
        />
      </div>
    </>
  );
}

export default Reviews;
