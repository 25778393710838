import React, {Component, useEffect, useRef, useState} from 'react';
import {withFauxDOM} from 'react-faux-dom'

import * as d3 from "d3";
import './style.css';

function ChartRowHeader(props) {
  let barMaxWidth = props.width - 180 - 40;
  let colRightMaxWidth = props.width - 180;
  const onScoreOrderClick = (e) => { props.onScoreOrderClick() };
  const onMentionOrderClick = (e) => { props.onMentionOrderClick() };
  return (
    <div className="chartRow header">
      <div className="colLeft">
        <div className="barBox">
          <span onClick={onMentionOrderClick}>
            Sort by mentions
            {
              !props.mentionOrderAscending
              ? <i className="fas fa-sort-amount-down"></i>
              : <i className="fas fa-sort-amount-up"></i>
            }
          </span>
        </div>
      </div>
      <div className="colRight" style={{'width': colRightMaxWidth}}>
        <div className="barBox">
          <span onClick={onScoreOrderClick}>
            {
              !props.scoreOrderAscending
              ? <i className="fas fa-sort-amount-down"></i>
              : <i className="fas fa-sort-amount-up"></i>
            }
            Sort by score
          </span>
        </div>
      </div>
    </div>
  )
}

function ChartRowAxis(props) {
  let barMaxWidth = props.width - 180 - 40;
  if (process.env.NODE_ENV !== 'production') {
    console.log('barMaxWidth: ', barMaxWidth, ' # maxValue: ', props.maxValue);
  }
  const axisValues = [0, 10, 20, 30, 40];
  let axisItemStyle = {};
  axisItemStyle['marginLeft'] = ((barMaxWidth/axisValues.length-1));
  return (
    <div className="chartRow chartRowAxis">
      <div className="colLeft">
        <div className="labelBox">
        </div>
      </div>
      {/*<div className="colTwo">
      </div>*/}
      <div className="colRight">
        <div className="barBox">
          Out of 100
          {/*
            axisValues.map((item) => {
              return (
                <div className="axisItem" style={axisItemStyle}>{item}</div>
              )
            })
          */}
        </div>
      </div>
    </div>
  )
}

function ChartRow(props) {
  const onMentionDetailsClick = () => {
    props.onMentionDetailsClick({ data: props.data });
  };

  let barMaxWidth = props.width - 180 - 40;
  if (process.env.NODE_ENV !== 'production') {
    console.log('barMaxWidth: ', barMaxWidth, ' # maxValue: ', props.maxValue);
  }
  let barShapeStyle = {};
  barShapeStyle['width'] = (barMaxWidth/props.maxValue)*props.data.score;
  return (
    <div className="chartRow">
      <div className="colLeft">
        <div className="labelBox">
          <p className="name">{props.data.name}</p>
          <p className="mention" onClick={onMentionDetailsClick}>{props.data.mentions} mentions</p>
        </div>
      </div>
      {/*<div className="colTwo">
      </div>*/}
      <div className="colRight">
        <div className="barBox">
          <div className={'barShape ' + (props.data.score > 50 ? 'right ' : 'left ')} style={barShapeStyle}>
          </div>
          <div className="barLabel">
            {props.data.score}
          </div>
        </div>
      </div>
    </div>
  )
}

function SentimentScoreChart(props) {
  const [margin, setMargin] = useState({ top: 60, right: 60, bottom: 60, left: 160 });
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(null);

  const [mentionOrderAscending, setMentionOrderAscending] = useState(false);
  const [scoreOrderAscending, setScoreOrderAscending] = useState(false);

  // useEffect(() => {
  //   let tempData = [{
  //               "name": "Room Amenities",
  //               "mentions": "12 mentions",
  //               "score": 20,
  //   },
  //       {
  //           "name": "Bananas",
  //           "mentions": "22 mentions",
  //           "score": 12,
  //   },
  //       {
  //           "name": "Grapes",
  //           "mentions": "16 mentions",
  //           "score": 19,
  //   },
  //       {
  //           "name": "Customer Support",
  //           "mentions": "12 mentions",
  //           "score": 5,
  //   },
  //       {
  //           "name": "Limes",
  //           "mentions": "42 mentions",
  //           "score": 16,
  //   },
  //       {
  //           "name": "Oranges",
  //           "mentions": "31 mentions",
  //           "score": 26,
  //   },
  //       {
  //           "name": "Pears",
  //           "mentions": "82 mentions",
  //           "score": 30,
  //   }];
  //   tempData = tempData.sort(function (a, b) {
  //     return d3.ascending(a.score, b.score);
  //   });
  //   setData(tempData);
  //   setMaxValue(tempData[tempData.length-1].score);
  // }, []);

  useEffect(() => {
    if (props.data) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('SentimentScoreChart useEffect data: ', props.data);
      }
      const { overall, weekly, monthly, review_data } = props.data;
      if (overall) {
        let tempData = [];
        Object.keys(overall).forEach((item) => {
          if ('overall' in overall[item] && item !== 'overall_score') {
            tempData.push({
              name: item,
              score: overall[item].overall,
              mentions: (overall[item].positive ? overall[item].positive.length : 0) +
                (overall[item].negative ? overall[item].negative.length : 0),
              details: overall[item],
            });
          }
        });
        tempData = tempData.sort(function (a, b) {
          return d3.ascending(a.score, b.score);
        });
        if (!mentionOrderAscending) {
          tempData.sort((a,b) => b.mentions - a.mentions);
        } else {
          tempData.sort((a,b) => a.mentions - b.mentions);
        }
        setData(tempData);
        if (tempData.length) {
          setMaxValue(tempData[tempData.length-1].score);
        }
      }
    }
  }, [props.data]);

  const onMentionOrderClick = (e) => {
    let tempData = [ ...data ];
    if (mentionOrderAscending) {
      tempData.sort((a,b) => b.mentions - a.mentions);
    } else {
      tempData.sort((a,b) => a.mentions - b.mentions);
    }
    setData(tempData);
    setMentionOrderAscending(mentionOrderAscending => !mentionOrderAscending);
    // gtag('event', 'gt_click', {
    //   'event_category': `Charts - Sentiments Score - Mention order`,
    //   'event_label': `Mention order`,
    //   'value': `${mentionOrderAscending}`,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onScoreOrderClick = (e) => {
    let tempData = [ ...data ];
    if (scoreOrderAscending) {
      tempData.sort((a,b) => b.score - a.score);
    } else {
      tempData.sort((a,b) => a.score - b.score);
    }
    setData(tempData);
    setScoreOrderAscending(scoreOrderAscending => !scoreOrderAscending);
    // gtag('event', 'gt_click', {
    //   'event_category': `Charts - Sentiments Score - Score order`,
    //   'event_label': `Score order`,
    //   'value': `${mentionOrderAscending}`,
    //   user_id: props.userprops.user.uid,
    // });
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('SentimentScoreChart data: ', data);
  }

  const cStyle = {};
  cStyle['width'] = props.width;
  const overallScore = props.data.overall && props.data.overall.overall_score ? props.data.overall.overall_score.overall : null;
  return (
    <div className="sentimentScoreChartWrap genericChartWrap" style={cStyle}>
      <div className="headerRow">
        <p className="title">Sentiments Score - {overallScore || ''}</p>
        <p className="subTitle">How are you performing overall and for on each aspect</p>
      </div>
      {
        data.length > 0 &&
        <ChartRowHeader
          mentionOrderAscending={mentionOrderAscending}
          scoreOrderAscending={scoreOrderAscending}
          onMentionOrderClick={onMentionOrderClick}
          onScoreOrderClick={onScoreOrderClick}
        />
      }
      {
        data.length > 0
        ?
          data.map((item) => {
            return <ChartRow key={`ssc_${item.name}`} data={item} width={props.width} maxValue={maxValue}
              onMentionDetailsClick={props.onMentionDetailsClick}
            />
          })
        :
          <p>No Data</p>
      }
      {
        data.length > 0 &&
        <div className="footer">
          Out of 100
        </div>
      }
    </div>
  )
}

export default withFauxDOM(SentimentScoreChart);
