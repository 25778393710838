import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { changeUsersName } from "../../redux/actions/changeUsersName";
import { changeUsersAge } from "../../redux/actions/changeUsersAge";

function About() {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [name, setName] = useState(user.name);
  const [age, setAge] = useState(user.name);

  let params = useParams();
  return (
    <div className="App">
      <h1>About</h1>
      <Link to="/home">Home</Link>
      <p onClick={(e) => {navigate('/home')}}>useNavigate Home</p>
      <h1>Invoice {params.invoiceId}</h1>;

      <h1>Name: {user.name}</h1>
      <h1>Age: {user.age}</h1>
      <hr></hr>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(changeUsersName(name));
          dispatch(changeUsersAge(age));
        }}
      >
        <input
          onChange={(e) => setName(e.target.value)}
          placeholder="Change name"
        ></input>
        <input
          onChange={(e) => setAge(e.target.value)}
          placeholder="Change age"
        ></input>
        <input type="submit" value="Change user details" />
      </form>

    </div>
  );
}

export default About;
