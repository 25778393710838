
import axios from 'axios';
import { sendEmailAlert } from '../../helpers';

const gtconfig = require('../../gtconfig');

const opMap = {
  'fs_get_sentiments_data': 'GET_SENTIMENTS_DATA',
  'fs_get_reviews_data_chart': 'GET_REVIEWS_DATA',
};

var lastOpTimeStamp = new Date().getTime();

/* ********************** Start - Get Data *********************************** */

export function getDataClearAction(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: `${opMap[payload.op]}_CLEAR`,
      payload,
    });
  };
}

export function getDataAction(payload) {
  return function somename(dispatch, getState) {

    if (process.env.NODE_ENV !== 'production') {
      console.log(`getDataAction ${payload.op} payload: ${JSON.stringify(payload)}`);
      console.log(`getDataAction lastOpTimeStamp: ${lastOpTimeStamp}`);
    }

    dispatch({
      type: opMap[payload.op],
      payload,
    });

    const { op, params, clear } = payload;

    if (clear) {
      dispatch({
        type: `${opMap[payload.op]}_CLEAR`,
        payload,
      });
    }

    let tDiff = new Date().getTime() - lastOpTimeStamp
    if (process.env.NODE_ENV !== 'production') {
      console.log(`getDataAction tDiff: ${tDiff}`);
    }
    let getToken = false;
    if (tDiff > 30 * 1000) getToken = true;
    if (process.env.NODE_ENV !== 'production') {
      console.log(`getDataAction tDiff getToken: ${getToken}`);
    }

        lastOpTimeStamp = new Date().getTime();
        const url = gtconfig.DATA_BACKEND_URL;
        if (process.env.NODE_ENV !== 'production') {
          console.log(`getDataAction ${payload.op} url: `, url);
        }
        const opdata = {};
        opdata.params = {
          ...params,
          relogin_identifier: window.gtCId,
        };
        const postData = {
          appId: gtconfig.APP_ID,
          op, // 'get_guest_list',
          opdata,
        };
        // try {
        //   const t1 = localStorage.getItem('nktest');
        //   if (process.env.NODE_ENV !== 'production') {
        //     console.log(`getDataAction t1: `, t1);
        //   }
        //   if (t1) {
        //     postData.c1 = t1;
        //   }
        // } catch (e) {
        //   if (process.env.NODE_ENV !== 'production') {
        //     console.log(`getDataAction localStorage e: `, e);
        //   }
        // }
        if (process.env.NODE_ENV !== 'production') {
          console.log(`getDataAction ${payload.op} postData: `, postData);
        }

        const headers = {
          'Content-type': 'application/json',
          'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
        };
        // const axiosData = {
        //   gtp: CryptoJS.AES.encrypt(JSON.stringify(postData), gtconfig.DATA_BACKEND_URL_GT_BASIC_KEY).toString(),
        // };
        const axiosData = {
          gtps: postData,
          M6Usp3JlX8gWCInDK3OX: 'Pk7giCRsG75yy8OcVHcN',
        };

        let timeout = 30000;

        axios
          .post(url, axiosData, { headers, timeout })
          .then((response) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(`getDataAction ${payload.op} response: `, response.data);
            }
            const { status } = response.data;
            const { data } = response.data;
            // if (process.env.NODE_ENV !== 'production') {
            //   console.log(`getDataAction origin: `, window.location.origin);
            // }
            // if (!data || !data.app_info || data.app_info.origin !== window.location.origin) {
            //   console.log('No way!');
            // }
            // if (status === 'success' && data) {
            //   dispatch({
            //     type: 'SET_VERSION',
            //     payload: { data, params },
            //   });
            // }
            if (status === 'success' && data) {
              dispatch({
                type: `${opMap[payload.op]}_SUCCESS`,
                payload: {
                  data,
                  params,
                },
              });
            } else {
              if (process.env.NODE_ENV !== 'production') {
                console.log(`getDataAction ${payload.op} error: `, response);
              }
              sendEmailAlert({
                app: gtconfig.APP_NAME,
                subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getDataAction(${payload.op || ''}) Error`,
                message: JSON.stringify({ postData, responseData: response.data }),
                error: JSON.stringify(response.data),
                store: JSON.stringify(getState()),
              });
              dispatch({
                type: `${opMap[payload.op]}_ERROR`,
                payload: {
                  data,
                  params,
                },
              });
              if (payload.show_error_alert) {
                const id = (new Date()).getTime();
                dispatch({
                  type: 'RECEIVED_NEW_SNACK',
                  payload: {
                    snack: {
                      id,
                      msg: payload.error_alert_msg || 'Error in updating!',
                    },
                  },
                });
              }
            }
          })
          .catch((err) => {
            // axios error
            if (process.env.NODE_ENV !== 'production') {
              console.log(`getDataAction ${payload.op} err--------------------: `, err);
              console.log(`getDataAction ${payload.op} err.response--------------------: `, err.response);
            }
            sendEmailAlert({
              app: gtconfig.APP_NAME,
              subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getDataAction(${payload.op || ''}) Error - axios`,
              message: JSON.stringify({ url, postData }),
              error: JSON.stringify({ err , config: err.config }),
              store: JSON.stringify(getState()),
            });
            dispatch({
              type: `${opMap[payload.op]}_ERROR`,
              payload: {
                params,
                error: err,
                errorMessage: err.toString(),
              },
            });
          });
  };
}

/* ***************************  End - Get Data  ****************************** */
