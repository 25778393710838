import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from 'moment';

import { getDataAction } from '../../redux/actions/getDataActions';

import SentimentScoreChart from '../Charts/SentimentScoreChart';
import SentimentTrendChart from '../Charts/SentimentTrendChart';
import SentimentMentionChart from '../Charts/SentimentMentionChart';
import SentimentMentions from './SentimentMentions';

import './style.css';

const gtconfig = require('../../gtconfig');

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function Sentiments(props) {
  const windowState = useSelector((state) => state.windowReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [showMentionDetailsModal, setShowMentionDetailsModal] = useState(false);
  const mentionDetailsModalRef = useRef();
  // useOnClickOutside(mentionDetailsModalRef, useCallback(() => onMentionDetailsModalCloseClick()));
  const [mentionDetailsData, setMentionDetailsData] = useState(null);
  const [mentionDetailsDefault, setMentionDetailsDefault] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  // const [name, setName] = useState(user.name);
  // const [age, setAge] = useState(user.name);

  const getData = () => {
    console.log('urlParams: ', urlParams);
    if (urlParams.locationidentifier && urlParams.appid && urlParams.dateparams) {
      let startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
      let endDate = moment().format('YYYY-MM-DD');
      if (urlParams.dateparams.indexOf('_') > -1) {
        let tArr = urlParams.dateparams.split('_');
        startDate = tArr[0];
        endDate = tArr[1];
      }
      dispatch(getDataAction({
        op: 'fs_get_sentiments_data',
        params: {
          location__id: urlParams.locationidentifier, // 'wZYeknJ4QgyGXj7Jm', // urlParams.locationidentifier,
          start_date: startDate,
          end_date: endDate,
        },
      }));
    }
  };

  const onMentionDetailsModalCloseClick = (e) => {
    setShowMentionDetailsModal(false);
  }

  const onMentionDetailsClick = (params) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onMentionDetailsClick: ', params);
    }
    if (!params.data.total_mentions && !params.data.mentions) return;
    setMentionDetailsData(params.data);
    setMentionDetailsDefault(params.default);
    setShowMentionDetailsModal(true);
  };
  const onAspectClick = () => {};
  const onTrendsTimePeriodClick = () => {};


  const renderMentionDetailsModal = () => {
    // const { show_details } = queryString.parse(location.search);
    // if (!show_details) return null;
    return (
      <Modal>
        <div id="cModelId" className="sdMdl mentionDetailsModal">
          <div className="ctnt" ref={mentionDetailsModalRef}>
            <SentimentMentions
              onCancelClick={onMentionDetailsModalCloseClick}
              data={mentionDetailsData}
              sentiments={locationState.reputation.sentiments || {}}
              default={mentionDetailsDefault}
              mode="modal"
              hasTranslate={false}
              locationidentifier={'abc' || ''}
              nkcheck={{}}
              userState={{}}
            />
          </div>
        </div>
      </Modal>
    );
  };

  if (!locationState.reputation) return null;

  const sentiments = locationState.reputation.sentiments || {};

  if (sentiments.getInProgress) return (
    <div className="gPnlSplLdng">
      <div className="innerWrap">
        <i className="fa fa-spinner fa-spin" />
        <img src={gtconfig.LOADER_URL} />
      </div>
    </div>
  )

  return (
    <>
    <div className="mb">
      <SentimentScoreChart
        width={(windowState.window.windowWidth)}
        height={260}
        data={sentiments}
        onMentionDetailsClick={onMentionDetailsClick}
      />
      <SentimentTrendChart
        windowState={windowState}
        width={(windowState.window.windowWidth)}
        height={260}
        data={sentiments}
        onMentionDetailsClick={onMentionDetailsClick}
        onAspectClick={onAspectClick}
        onTimePeriodClick={onTrendsTimePeriodClick}
      />
      <SentimentMentionChart
        width={(windowState.window.windowWidth)}
        height={260}
        data={sentiments}
        onMentionDetailsClick={onMentionDetailsClick}
      />
    </div>
    { showMentionDetailsModal && renderMentionDetailsModal() }
    </>
  );
}

export default Sentiments;
