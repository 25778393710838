// @flow

import React, { Component, useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter, Link, useParams } from 'react-router-dom';
import Select from 'react-select';

import { renderOTALogo, formatDate, getReviewState, RS, mapReviewStateToString, OTA_SOURCE_TO_NAME_FORMAL_MAP,
  sendEmailAlert, LANGUAGE_LIST_KEYS } from '../../../helpers';

import moreIcon from '../../../img/icons/more.svg';
import closeIcon from '../../../img/icons/close.svg';

import './style.css';

const gtconfig = require('../../../gtconfig');


const filterOptions = [];
filterOptions.push({ label: 'Positive', value: 'positive' });
filterOptions.push({ label: 'Negative', value: 'negative' });
filterOptions.push({ label: 'All', value: 'all' });

function SingleRow(props) {
  const [showReview, setShowReview] = useState(false);
  const onShowReviewClick = (e) => {
    setShowReview(showReview => !showReview);
  }
  let reviewTitle = '', reviewText = '', reviewTextNegative = '', reviewDate = '', reviewRating = '', reviewSource = '', reviewAuthor = '';
  let reviewHasManagerResponse = false, reviewManagerResponse = '';
  let hotelName = '';
  if (props.data.review_id && props.reviewData && props.reviewData[props.data.review_id]) {
    reviewTitle = props.reviewData[props.data.review_id].title;
    reviewText = props.reviewData[props.data.review_id].comment;
    reviewTextNegative = props.reviewData[props.data.review_id].comment_negative;
    reviewDate = props.reviewData[props.data.review_id].review_date;
    reviewRating = props.reviewData[props.data.review_id].rating_out_of_5;
    reviewSource = props.reviewData[props.data.review_id].source;
    reviewAuthor = props.reviewData[props.data.review_id].author;
    reviewHasManagerResponse = props.reviewData[props.data.review_id].has_manager_response;
    reviewManagerResponse = props.reviewData[props.data.review_id].manager_response;
    hotelName = props.reviewData[props.data.review_id].hotel_name;
  }
  var starStyle = { "--rating": reviewRating };
  return (
    <div className={'singleRow ' + (props.rowType)}>
      <div className="icon">
      </div>
      <div className="text">
        {
          props.data.text
        }
        {
          reviewText && <span onClick={onShowReviewClick} className="showReview">{showReview ? 'Hide' : 'See'} review context</span>
        }
        {
          showReview &&
          <div className="reviewText">
            { hotelName && <p className="hName">{hotelName}</p> }
            {reviewDate && <p>{ renderOTALogo(reviewSource) } On {formatDate(reviewDate)}</p> }
            {reviewAuthor && <p>By {reviewAuthor}</p> }
            {reviewRating &&
              <div className="rating">
                <div className="ratingValue">
                  {reviewRating} / 5
                </div>
                <div className="stars">
                  <div className="reviewStars" style={starStyle}></div>
                </div>
              </div>
            }
            {reviewTitle && <p><b>{reviewTitle}</b></p> }
            {reviewText && <p>{reviewText}</p> }
            {reviewTextNegative && <p>{reviewTextNegative}</p> }
            {
              reviewHasManagerResponse &&
              <div className="mgrRsp">
                <p className="title">Your Response</p>
                {reviewManagerResponse && <p>{reviewManagerResponse}</p>}
              </div>
            }
          </div>
        }
      </div>
    </div>
  );
}

function SentimentMentions(props) {
  const dispatch = useDispatch();
  let urlParams = useParams();

  const [keyword, setKeyword] = useState('');
  const [showPositive, setShowPositive] = useState(true);
  const [showNegative, setShowNegative] = useState(true);
  const [filterValue, setFilterValue] = useState(
    { label: 'All', value: 'all' }
  );

  const [showOriginal, setShowOriginal] = useState(true);

  const { locationidentifier } = props;

  useEffect(() => {
    if (!props.default || props.default === 'all') {
      setShowPositive(true); setShowNegative(true);
      setFilterValue({ label: 'All', value: 'all'});
    }
    if (props.default === 'positive') {
      setShowPositive(true); setShowNegative(false);
      setFilterValue({ label: 'Positive', value: 'positive'});
    }
    if (props.default === 'negative') {
      setShowPositive(false); setShowNegative(true);
      setFilterValue({ label: 'Negative', value: 'negative'});
    }
  }, [props.default]);

  const onKeywordChange = (e) => {
    const { name, value } = e.target;
    setKeyword(value);
    // if (value) {
    //   gtag('event', 'search', {
    //     'search_term': value,
    //   });
    //   gtag('event', 'gt_click', {
    //     'event_category': `Charts - Sentiments Mentions - Mention Details - Filter - Keyword`,
    //     'event_label': `Keyword`,
    //     'value': value,
    //     user_id: props.userprops.user.uid,
    //   });
    // }
  };
  const onKeywordKeyDown = (e) => {
    if (e.keyCode === 27) {
      setKeyword('');
    }
  };

  const onPositiveClick = (e) => {
    setShowPositive(showPositive => !showPositive);
  };
  const onNegativeClick = (e) => {
    setShowNegative(showNegative => !showNegative);
  };

  const onFilterOptionChange = (value) => {
    const filterValue = value.value;
    setFilterValue(value);
    if (filterValue === 'all') {
      setShowPositive(true);
      setShowNegative(true);
    }
    if (filterValue === 'positive') {
      setShowPositive(true);
      setShowNegative(false);
    }
    if (filterValue === 'negative') {
      setShowPositive(false);
      setShowNegative(true);
    }
  };


  const renderPositive = (details, reviewData) => {
    if (showOriginal) {
      if (!details || !details.positive) return null;
      return details.positive.map((item) => {
        if (keyword) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('keyword: ', item.text.search(new RegExp(keyword, "i")));
          }
          if (
            item.text.search(new RegExp(keyword, "i")) > -1 ||
            (reviewData[item.review_id] && reviewData[item.review_id].hotel_name && reviewData[item.review_id].hotel_name.search(new RegExp(keyword, "i")) > -1)
          ) {
            return <SingleRow data={item} reviewData={reviewData} rowType="positive" />
          }
          return null;
        }
        return <SingleRow data={item} reviewData={reviewData} rowType="positive" />
      });
    }
    return null;
  };
  const renderNegative = (details, reviewData) => {
    if (showOriginal) {
      if (!details || !details.negative) return null;
      return details.negative.map((item) => {
        if (keyword) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('keyword: ', item.text.search(new RegExp(keyword, "i")));
          }
          if (
            item.text.search(new RegExp(keyword, "i")) > -1 ||
            (reviewData[item.review_id] && reviewData[item.review_id].hotel_name && reviewData[item.review_id].hotel_name.search(new RegExp(keyword, "i")) > -1)
          ) {
            return <SingleRow data={item} reviewData={reviewData} rowType="negative" />
          }
          return null;
        }
        return <SingleRow data={item} reviewData={reviewData} rowType="negative" />
      });
    }
    return null;
  };

  const renderContent = () => {
    const { name, details } = props.data;
    if (!name || !details) return null;
    let reviewData = props.sentiments && props.sentiments.review_data ? props.sentiments.review_data : {};
    return (
      <div className="gPWrp sentMentBx">
        <div className="gPTle title">
          Mentions - {name}
          <span className="close" onClick={props.onCancelClick}><img src={closeIcon} />Close</span>
        </div>
        <div className="gBCtnt">
          <div className="innerBox">
            <div className="optionsRow">
              <div className="search">
                <div className="gIpt">
                  <input type="text"
                    placeholder="Search"
                    value={keyword}
                    onChange={onKeywordChange}
                  />
                </div>
              </div>
              <div className="filter">
                <Select
                  className="sentimentPositiveNegativeFilter"
                  options={filterOptions}
                  onChange={onFilterOptionChange}
                  value={filterValue}
                  onKeyDown={onKeywordKeyDown}
                />
              </div>
            </div>
            <div className="contentBox">
              { showPositive && renderPositive(details, reviewData) }
              { showNegative && renderNegative(details, reviewData) }
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    // console.log('SingleReview locationId: ', props.locationId);
    // console.log('SingleReview reviewId: ', props.reviewId);
    console.log('SentimentMentions props: ', props);
  }

  return renderContent();

  return (
    <div className="gBoxWrap sentimentMentionsWrap">
        { renderContent() }
    </div>
  );
}

export default SentimentMentions;
