import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Wrapper from '../components/Wrapper';
import Home from '../components/Home';

const routes = (
  <BrowserRouter>
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/*" element={<Wrapper />} />
    </Routes>
</BrowserRouter>
);

export default routes;
