import React, {Component, useEffect, useRef, useState} from 'react';
import {withFauxDOM} from 'react-faux-dom';
import moment from 'moment';

import * as d3 from "d3";
import './style.css';

const MIN_BAR_WIDTH = 30;

function AspectButton(props) {
  const onButtonClick = (e) => { props.onAspectButtonClick(props.aspect); }
  let label = props.aspect;
  if (props.aspect === 'overall_aspect') label = 'All';
  return (
    <div className={'entry ' + (props.selected ? 'active' : '')} onClick={onButtonClick}>
      <span>{label}</span>
    </div>
  );
}

function TimePeriodButton(props) {
  const onButtonClick = (e) => { props.onButtonClick(props.value); }
  return (
    <div className={'entry ' + (props.selected ? 'active' : '')} onClick={onButtonClick}>
      <span>{props.label}</span>
    </div>
  );
}

function SentimentTrendChart(props) {
  const svgRef = useRef();
  const [margin, setMargin] = useState({ top: 60, right: 60, bottom: 60, left: 60 });
  const [barWidth, setBarWidth] = useState(40);
  const [data, setData] = useState([]);
  const [canvasHeight, setCanvasHeight] = useState(360);
  const [canvasWidth, setCanvasWidth] = useState(360);

  const [chartUpdating, setChartUpdating] = useState(false);

  const [selectedAspect, setSelectedAspect] = useState('overall_aspect');

  const [selectedTimePeriod, setSelectedTimePeriod] = useState('monthly');


  useEffect(() => {
    setCanvasHeight(props.height);
    // setCanvasWidth(props.windowprops.window.windowWidth/2 - 100);
    setCanvasWidth(props.width - 120 - 20);
    setData([
      { period: 'jan', value: 2, rating: 4 },
      { period: 'feb', value: 14, rating: 5},
      { period: 'mar', value: 6, rating: 1 },
      { period: 'apr', value: 8, rating: 2 },
      { period: 'may', value: 10, rating: 5 },
    ]);
    drawChart();
  }, []);

  useEffect(() => {
    if (props.data) {
      const { overall, weekly, monthly, review_data } = props.data;
      if (overall && weekly && monthly) {
        let tempData = [];
        if (selectedTimePeriod === 'weekly') {
          // change it to monthly
          if (canvasWidth/Object.keys(weekly).length < MIN_BAR_WIDTH) {
            setData(prepMonthlyData(monthly));
            setBarWidth((canvasWidth/Object.keys(props.data.monthly).length)/2);
            setSelectedTimePeriod('monthly');
            setChartUpdating(true);
          } else {
            // change it to weekly
            setData(prepWeeklyData(props.data.weekly));
            setBarWidth((canvasWidth/Object.keys(props.data.weekly).length)/2);
            setSelectedTimePeriod('weekly');
            setChartUpdating(true);
          }
          // setData(prepWeeklyData(weekly));
          // setBarWidth((canvasWidth/Object.keys(weekly).length)/2);
        }
        if (selectedTimePeriod === 'monthly') {
          setData(prepMonthlyData(monthly));
          setBarWidth((canvasWidth/Object.keys(monthly).length)/2);
        }
      }
    }
  }, [props.data, selectedAspect]);

  // useEffect(() => {
  //   if (props.data) {
  //     console.log('props.data changed: ', props.data);
  //     let tempCanvasWidth = props.width - 120 - 20;
  //     if (selectedTimePeriod === 'daily' && props.data.daily) {
  //       if (tempCanvasWidth/props.data.daily.length < MIN_BAR_WIDTH) {
  //         // change it to monthly
  //         if (tempCanvasWidth/props.data.weekly.length < MIN_BAR_WIDTH) {
  //           setData(props.data.monthly);
  //           setBarWidth((tempCanvasWidth/props.data.monthly.length)/2);
  //           setSelectedTimePeriod('monthly');
  //         } else {
  //           // change it to weekly
  //           setData(props.data.weekly);
  //           setBarWidth((tempCanvasWidth/props.data.weekly.length)/2);
  //           setSelectedTimePeriod('weekly');
  //         }
  //       } else {
  //         setData(props.data.daily);
  //         setBarWidth((tempCanvasWidth/props.data.daily.length)/2);
  //       }
  //     }
  //     if (selectedTimePeriod === 'weekly' && props.data.weekly) {
  //       // change it to monthly
  //       if (tempCanvasWidth/props.data.weekly.length < MIN_BAR_WIDTH) {
  //         setData(props.data.monthly);
  //         setBarWidth((tempCanvasWidth/props.data.monthly.length)/2);
  //         setSelectedTimePeriod('monthly');
  //       } else {
  //         // change it to weekly
  //         setData(props.data.weekly);
  //         setBarWidth((tempCanvasWidth/props.data.weekly.length)/2);
  //         setSelectedTimePeriod('weekly');
  //       }
  //     }
  //     if (selectedTimePeriod === 'monthly' && props.data.monthly) {
  //       setData(props.data.monthly);
  //       setBarWidth((tempCanvasWidth/props.data.monthly.length)/2);
  //     }
  //   }
  //   if (props.hideRating) {
  //     setSelectedChartType('reviews');
  //   }
  //   console.log('showOnlyDaily: ', props.showOnlyDaily);
  //   if (props.showOnlyDaily) {
  //     setSelectedTimePeriod('daily');
  //   }
  // }, [props.data, props.hideRating, props.showOnlyDaily]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('data changed');
    }
    setChartUpdating(true);
    updateChart(selectedTimePeriod);
  }, [data, canvasWidth, canvasHeight]);

  useEffect(() => {
    setCanvasHeight(props.height);
    setCanvasWidth(props.width - 120 - 20);
    updateChart(selectedTimePeriod);
  }, [props.windowState.window.windowHeight]);

  const onTimePeriodButtonClick = (timePeriod) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onTimePeriodButtonClick: ', timePeriod);
    }
    if (selectedTimePeriod === timePeriod) return;
    setSelectedTimePeriod(timePeriod);
    if (timePeriod === 'monthly') {
      if (props.data.monthly) {
        setData(prepMonthlyData(props.data.monthly));
        // setData(props.data.monthly);
        setBarWidth((canvasWidth/Object.keys(props.data.monthly).length)/2);
        setChartUpdating(true);
      }
    }
    if (timePeriod === 'weekly') {
      // change it to monthly
      if (canvasWidth/Object.keys(props.data.weekly).length < MIN_BAR_WIDTH) {
        setData(prepMonthlyData(props.data.monthly));
        // setData(props.data.monthly);
        setBarWidth((canvasWidth/Object.keys(props.data.monthly).length)/2);
        setSelectedTimePeriod('monthly');
        setChartUpdating(true);
      } else {
        // change it to weekly
        setData(prepWeeklyData(props.data.weekly));
        // setData(props.data.weekly);
        setBarWidth((canvasWidth/Object.keys(props.data.weekly).length)/2);
        setSelectedTimePeriod('weekly');
        setChartUpdating(true);
      }
    }
    props.onTimePeriodClick(timePeriod);
    // gtag('event', 'gt_click', {
    //   'event_category': `Charts - Sentiments Trend - Time Scale`,
    //   'event_label': `Time Scale`,
    //   'value': `${timePeriod}`,
    //   user_id: props.userprops.user.uid,
    // });
  }

  const onMentionDetailsClick = (params) => {
    props.onMentionDetailsClick(params);
  };

  const onToggleData = (e) => {
    let newData = [ ...data ];
    setData([ ...data, { period: 'jun', value: 50 }]);
  };

  const onAspectButtonClick = (aspect) => {
    setSelectedAspect(aspect);
    props.onAspectClick(aspect);
    // gtag('event', 'gt_click', {
    //   'event_category': `Charts - Sentiments Trend - Aspect`,
    //   'event_label': `Aspect`,
    //   'value': `${aspect}`,
    //   user_id: props.userprops.user.uid,
    // });
  }

  const prepWeeklyData = (weekly) => {
    if (!weekly) return;
    if (process.env.NODE_ENV !== 'production') {
      console.log('prepWeeklyData weekly: ', weekly);
      console.log('prepWeeklyData selectedAspect: ', selectedAspect);
    }
    let tempData = [];
    Object.keys(weekly).forEach((item) => {
      if (selectedAspect === 'overall_aspect') {
        if ('overall_score' in weekly[item]) {
          // let tempPositive = [], tempNegative = [];
          // Object.keys(weekly[item]).forEach((aspect) => {
          //   if (weekly[item][aspect].positive) { tempPositive = tempPositive.concat(weekly[item][aspect].positive); }
          //   if (weekly[item][aspect].negative) { tempNegative = tempNegative.concat(weekly[item][aspect].negative); }
          // });
          tempData.push({
            period: item,
            rating: weekly[item].overall_score.overall,
            // positive: tempPositive,
            // negative: tempNegative,
          });
        }
      } else {
        if (weekly[item][selectedAspect] && 'overall' in weekly[item][selectedAspect]) {
          tempData.push({
            period: item,
            rating: weekly[item][selectedAspect].overall,
            positive: weekly[item][selectedAspect].positive,
            negative: weekly[item][selectedAspect].negative,
          });
        }
      }
    });
    if (process.env.NODE_ENV !== 'production') {
      console.log('prepWeeklyData tempData: ', tempData);
    }
    tempData.sort((a,b) => moment(a.period, 'Do MMM YYYY') - moment(b.period, 'Do MMM YYYY'));
    return tempData;
  };

  const prepMonthlyData = (monthly) => {
    if (!monthly) return;
    let tempData = [];
    Object.keys(monthly).forEach((item) => {
      if (selectedAspect === 'overall_aspect') {
        if ('overall_score' in monthly[item]) {
          tempData.push({
            period: item,
            rating: monthly[item].overall_score.overall,
          });
        }
      } else {
        if (monthly[item][selectedAspect] && 'overall' in monthly[item][selectedAspect]) {
          tempData.push({
            period: item,
            rating: monthly[item][selectedAspect].overall,
            positive: monthly[item][selectedAspect].positive,
            negative: monthly[item][selectedAspect].negative,
          });
        }
      }
    });
    tempData.sort((a,b) => moment(a.period, 'MMM YYYY') - moment(b.period, 'MMM YYYY'));
    return tempData;
  };




  const scaleValueXAxis = d3.scaleBand()
    // .domain([0,1,2,3,4])
    .domain(d3.range(0, data.length))
    .range([0,canvasWidth]);

  const scaleValueYAxis = d3.scaleLinear()
    .domain([0, d3.max(data, function(d) { return d.rating; })])
    .range([canvasHeight,0]);


  const scaleValueXAxisOrdinal = d3.scaleBand()
  // .domain(["jan","feb","mar","apr","may"])
  .domain(data.map(el => el.period))
  .range([0, canvasWidth]);


  const drawChart = () => {
    // const margin = 30;

    const faux = props.connectFauxDOM('div', 'chart');

    const scale = 20;
    // const data = [ 2, 4, 2, 6, 8 ];
    // const svgCanvas = d3.select(svgRef.current)
    const svgCanvas = d3.select(faux)
      .append('svg')
      .attr('width', canvasWidth + margin.left + margin.right)
      .attr('height', canvasHeight + margin.top + margin.bottom)
      // .style('border', '1px solid #EEE')


    // Add scale to Y axis
    const y_axis = d3.axisLeft()
                   .scale(scaleValueYAxis);

    // Add Y axis
    svgCanvas.append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .call(y_axis);

    // Add scale to X axis
    const x_axis = d3.axisBottom()
                   .scale(scaleValueXAxisOrdinal);

    // Add X axis
    svgCanvas.append("g")
    .attr("class", "x axis")
      .attr("transform", "translate(" + margin.left + "," + (canvasHeight+margin.bottom) + ")")
      .call(x_axis);

    // Add g tag for line chart
    svgCanvas.append("g")
      .attr('id', 'lineChart');

    // Define the div for the tooltip
    const div = d3.select("body").append("div")
      .attr("class", "barChartTooltip")
      .style("opacity", 0);

  }

  const updateChart = (timePeriod) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('updateChart: ', (new Date()).getTime(), ' data: ', data);
    }

    setChartUpdating(true);
    d3.select('#chartLoader').style('display', 'block');
    if (process.env.NODE_ENV !== 'production') {
      console.log('updateChart after setChartUpdating: ', (new Date()).getTime());
    }

    // showLoader();
    // d3.select("#loader_container")
    // .attr('class', 'show')

    const faux = props.connectFauxDOM('div', 'chart')

    // const svgCanvas = d3.select(svgRef.current)
    const svgCanvas = d3.select(faux)
      .select('svg')
      .attr('width', canvasWidth + margin.left + margin.right)
      .attr('height', canvasHeight + margin.top + margin.bottom)

      if (process.env.NODE_ENV !== 'production') {
        console.log('updateChart svgCanvas: ', (new Date()).getTime(), ' # ', svgCanvas);
      }

    scaleValueYAxis.domain([0, d3.max(data, function(d) { return d.rating; })]);

    scaleValueXAxis.domain(d3.range(0, data.length));

    scaleValueXAxisOrdinal.domain(data.map(el => el.period));

    // const barWidthLocal = (canvasWidth/Object.keys(props.data.monthly).length)/2;

    const entryOffset = (canvasWidth - barWidth * data.length)/(data.length*2);
    if (process.env.NODE_ENV !== 'production') {
      console.log('updateChart entryOffset: ', entryOffset);
    }

    // Remove Y axis and Y axis label
    svgCanvas
      .select('.y.axis')
      .remove();

    svgCanvas
      .select('#ylabel')
      .remove();


    // Add y axis
    svgCanvas.append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      // .attr("transform", "translate("  + (canvasWidth + margin.left) + "," + margin.top + ")")
      .call(d3.axisLeft()
        .ticks(5)
        .scale(scaleValueYAxis));

    // Add y axis label
    svgCanvas.append("text")
      .attr("id", "ylabel")
      .attr("y",  30)
      .attr("x", margin.left - 40 )
      .text("Score (out of 100)")


    // let xAxisTransform = "translate("  + (-7) + "," + (20) + "), rotate(0)";
    // if (selectedTimePeriod === 'weekly') {
    //   xAxisTransform = "translate("  + (-7) + "," + (45) + "), rotate(-25)";
    // }
    // if (selectedTimePeriod === 'monthly') {
    //   xAxisTransform = "translate("  + (-7) + "," + (45) + "), rotate(-25)";
    // }
    let xAxisTransform = "translate("  + (-40) + "," + (50) + "), rotate(-60)";
    if (selectedTimePeriod === 'weekly') {
      xAxisTransform = "translate("  + (-40) + "," + (45) + "), rotate(-25)";
    }
    if (selectedTimePeriod === 'monthly') {
      xAxisTransform = "translate("  + (-10) + "," + (30) + "), rotate(-30)";
    }
    // Add X axis
    svgCanvas.select(".x.axis") // change the x axis
      // .duration(750)
      // .transition()
      // .duration(750)
      .attr("transform", "translate(" + margin.left + "," + (canvasHeight+margin.top) + ")")
      .call(d3.axisBottom()
              .scale(scaleValueXAxisOrdinal)
      )
      .selectAll("text")
      .attr("y", 0)
      .attr("x", 0)
      // .attr("transform", "rotate(-60)")
      .attr("transform", xAxisTransform)
      .style("text-anchor", "start")
      .style("font-size", "10px")


    // Remove line chart, dots and dots text
    svgCanvas
      .select('#lineChart')
      .selectAll('path')
      .remove();
    svgCanvas
      .select('#lineChart')
      .selectAll('.dot')
      .remove();
    svgCanvas
      .select('#lineChart')
      .selectAll('.dotText')
      .remove();

    // Define the line
    const valueline = d3.line()
      .x(function(d, i) { return scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2; })
      .y(function(d, i) { return scaleValueYAxis(d.rating) + margin.top });

    // Add the valueline path.


    svgCanvas
      .select('#lineChart')
      .append('path')
      .data([data])
      .attr("class", "line")
      .attr("d", valueline);

    var div = d3.select(".barChartTooltip");

    // Appends a circle for each datapoint
    svgCanvas
      .select('#lineChart')
      .selectAll(".dot")
      .data(data).enter()
      .append("circle") // Uses the enter().append() method
      .attr("class", "dot") // Assign a class for styling
      .attr("cx", function(d, i) { return scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2; })
      // .attr("cx", function(d, i) { return scaleValueXAxis(i) + margin.left + 15; })
      .attr("cy", function(d) { return scaleValueYAxis(d.rating) + margin.top + 5; })
      .attr("r", 8)
      .on("mouseover", (e, d, i) => {
        d3.select(this).transition()
          .duration('50')
          .attr('opacity', '.5')
        div.transition()
          .duration(200)
          .style("opacity", .9);
          if (process.env.NODE_ENV !== 'production') {
            console.log('mouseover d: ', d);
          }
        div.html(`Positive: ${d.positive ?  d.positive.length : '-'}` + "<br/>"  + `Negative: ${d.negative ? d.negative.length : '-'}`)
          .style("left", (e.pageX) + "px")
          .style("top", (e.pageY - 28) + "px")
          // .on("mouseout", (d) => {
          //   console.log('mouseout popup');
          //   div.transition()
          //     .duration('500')
          //     .attr('opacity', '0')
          // });
      })
      .on("mouseout", (d) => {
        // d3.select(this).transition()
        //   .duration('50')
        //   .attr('opacity', '1')
        setTimeout(() => {
          div.transition()
            .duration(500)
            .style("opacity", 0);
        }, 1000);
      })
      .on("click", (e, d) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('click: ', e, ' d: ', d);
        }
        if (selectedAspect !== 'overall_aspect') {
          const tempData = { name: selectedAspect };
          tempData.details = {
            positive: d.positive,
            negative: d.negative,
          }
          // props.onMentionDetailsClick(tempData);
          onMentionDetailsClick({ data: tempData, default: 'all'});
          // setSelectedTimePeriod('weekly');
        }
      });



    // add text on top of dot
    svgCanvas
      .select('#lineChart')
      .selectAll('text')
      .data(data).enter()
      .append('text')
      .attr('class', 'dotText')
      .attr('x', (d, i) => scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2 - 10)
      // .attr('x', (dataPoint, i) => i * 45 + 15)
      .attr('y', (d) => (scaleValueYAxis(d.rating) + margin.top - 5))
      // .attr('y', (dataPoint, i) => canvasHeight - scaleValueYAxis(dataPoint.value) - 10)
      .text(dataPoint => dataPoint.rating)

      if (process.env.NODE_ENV !== 'production') {
        console.log('updateChart end: ', (new Date()).getTime());
      }

    // d3.select("#loader_container")
    // .attr('class', 'hide')
    // hideLoader();

    props.animateFauxDOM(800)

    d3.select('#chartLoader').style('display', 'none');

    setTimeout(() => {
      setChartUpdating(false);
    }, 50);
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('barWidth: ', barWidth);
    console.log('selectedTimePeriod: ', selectedTimePeriod);
    console.log('data: ', data);
    console.log('chartUpdating time: ', (new Date()).getTime());
    console.log('chartUpdating: ', chartUpdating);
  }

  const cStyle = {};
  cStyle['width'] = props.width;

  return (
    <div className="sentimentTrendChartWrap genericChartWrap" style={cStyle}>
      <div id="chartLoader" className="chartLoader">
        <i className="fa fa-spinner fa-spin" />
      </div>
      <div className="headerRow">
        <p className="title">Sentiments Trend</p>
        <p className="subTitle">What is the trend for different aspects</p>
      </div>
      {
        props.data.all_aspects && Object.keys(props.data.all_aspects).length
        ?
        <>
          <div className="aspectsRow">
            <AspectButton
              aspect={'overall_aspect'}
              selected={selectedAspect === 'overall_aspect'}
              onAspectButtonClick={onAspectButtonClick}
            />
            {
              props.data.all_aspects &&
              Object.keys(props.data.all_aspects).map((item) => {
                return <AspectButton
                  key={`stc_${item}`}
                  aspect={item}
                  selected={selectedAspect === item}
                  onAspectButtonClick={onAspectButtonClick}
                />
              })
            }
          </div>
          <div className="topRow">
            <div className="chartTypeBox">
            </div>
          </div>
          {props.chart}
          <div className="bottomRow">
            <div className="timePeriodBox">
              {
                props.data.monthly && (canvasWidth/Object.keys(props.data.monthly).length > MIN_BAR_WIDTH) &&
                <TimePeriodButton label={'Monthly'} value={'monthly'} onButtonClick={onTimePeriodButtonClick} selected={selectedTimePeriod === 'monthly'} />
              }
              {
                props.data.monthly && (canvasWidth/Object.keys(props.data.weekly).length > MIN_BAR_WIDTH) &&
                <TimePeriodButton label={'Weekly'} value={'weekly'} onButtonClick={onTimePeriodButtonClick} selected={selectedTimePeriod === 'weekly'} />
              }
            </div>
          </div>
        </>
        : <p>No Data</p>
      }
    </div>
  )
}

export default withFauxDOM(SentimentTrendChart);
