import { combineReducers } from "redux";
import windowReducer from "./windowReducer";
import locationReducer from "./locationReducer";

const allReducers = combineReducers({
  windowReducer: windowReducer,
  locationReducer: locationReducer,
});

export default allReducers;
