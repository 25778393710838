
/***************************************** Get Sentiments Data ****************************/

export const getSentimentsData = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    reputation: {
      ...state.reputation,
      sentiments: state.reputation.sentiments
      ?
        {
          ...state.reputation.sentiments,
          getInProgress: true, getStatus: {},
        }
      : {
          getInProgress: true, getStatus: {},
        }
    },
  };
};

export const getSentimentsDataSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { sentiments_data } = data;
  return {
    ...state,
    reputation: {
      ...state.reputation,
      sentiments: {
        ...state.reputation.sentiments,
        ...sentiments_data,
        getInProgress: false, getStatus: { success: true },
      },
    },
  };
};

export const getSentimentsDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    reputation: {
      ...state.reputation,
      sentiments: {
        ...state.reputation.sentiments,
        getInProgress: false, getStatus: { error: true },
        error,
        errorMessage,
      },
    },
  };
};



/***************************************** Get Reviews Data ****************************/

export const getReviewsData = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    reputation: {
      ...state.reputation,
      reviews: state.reputation.sentiments
      ?
        {
          ...state.reputation.reviews,
          getInProgress: true, getStatus: {},
        }
      : {
          getInProgress: true, getStatus: {},
        }
    },
  };
};

export const getReviewsDataSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { trend_data } = data;
  return {
    ...state,
    reputation: {
      ...state.reputation,
      reviews: {
        ...state.reputation.reviews,
        trend_data,
        getInProgress: false, getStatus: { success: true },
      },
    },
  };
};

export const getReviewsDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    reputation: {
      ...state.reputation,
      reviews: {
        ...state.reputation.reviews,
        getInProgress: false, getStatus: { error: true },
        error,
        errorMessage,
      },
    },
  };
};
