import { useSelector, useDispatch } from "react-redux";
import { changeUsersName } from "./redux/actions/changeUsersName";
import { changeUsersAge } from "./redux/actions/changeUsersAge";
import "./App.css";
import { useState } from "react";

import routes from './routes';

function App() {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  return (
      <>
          { routes }
      </>
    );

  return (
    <div className="App">
      <hr>AAA</hr>
    </div>
  );
}

export default App;
