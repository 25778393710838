import moment from 'moment';
import axios from 'axios';

const CryptoJS = require('crypto-js');

const gtconfig = require('../gtconfig');

export function renderOTALogo(source) {
  switch (source) {
    case 2:
      //return ( <img className="logo" src={TripadvisorLogo} /> )
      return ( <img className="logo" src={gtconfig.TRIPADVISOR_LOGO} /> )
    case 3:
      return ( <img className="logo" src={gtconfig.BOOKING_LOGO} /> )
    case 4:
      return ( <img className="logo" src={gtconfig.EXPEDIA_LOGO} /> )
    case 5:
      return ( <img className="logo" src={gtconfig.GOOGLE_LOGO} /> )
    case 6:
      return ( <img className="logo" src={gtconfig.HOTELSCOM_LOGO} /> )
    case 11:
      return ( <img className="logo" src={gtconfig.HOSTELWORLD_LOGO} /> )
    case 12:
      return ( <img className="logo" src={gtconfig.MMT_LOGO} /> )
    case 14:
      return ( <img className="logo" src={gtconfig.AGODA_LOGO} /> )
    case 15:
      return ( <img className="logo" src={gtconfig.AIRBNB_LOGO} /> )
    case 16:
      return ( <img className="logo" src={gtconfig.VRBO_LOGO} /> )
    case 17:
      return ( <img className="logo" src={gtconfig.HOLIDAYCHECK_LOGO} /> )
    case 18:
      return ( <img className="logo" src={gtconfig.YELP_LOGO} /> )
    default:
      return null;
  }
}

function sendEmailAlertLocal(params) {
  const url = gtconfig.EMAIL_ALERT_URL;
  const opdata = {};
  opdata.params = params;
  const postData = {
    op: 'send_email_alert',
    opdata,
  };
  if (process.env.NODE_ENV !== 'production') {
    console.log('postData: ', postData);
  }

  const headers = {
    'Content-type': 'application/json',
    'x-api-key': gtconfig.EMAIL_ALERT_API_KEY,
  };
  const axiosData = {
    // nkcheck - needed
    gtp: CryptoJS.AES.encrypt(JSON.stringify(postData), gtconfig.EMAIL_ALERT_URL_GT_BASIC_KEY).toString(),
    // gtp: JSON.stringify(postData),
  };

  axios.post(url, axiosData, { headers }).then((response) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('sendEmailAlertLocal response: ', response.data);
    }
    const { status } = response.data;
    if (status !== 'success') {
      if (process.env.NODE_ENV !== 'production') {
        console.log('sendEmailAlertLocal success: ', response);
      }
    }
  })
    .catch((err) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('sendEmailAlertLocal err--------------------: ', err);
      }
    });
}

export const formatDate = (date) => {
  return moment.utc(date).local().format('Do MMM YYYY');
};

export function sendEmailAlert(params) {
  sendEmailAlertLocal(params);
}
